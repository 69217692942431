<template>
  <div class="page" v-loading="pageLoading">
    <div class="header">
        <div class="w1200">
          <a href=""><img src="../assets/images/logo.png"/></a>
        </div>
      </div>
    <div class="position">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item
          :to="{ path: './detail2?activeTab=2&id=' + goods_id }"
          >{{ baseInfo ? baseInfo.name : '' }}</el-breadcrumb-item
        >
        <el-breadcrumb-item>{{
          currentInfo.name || "课程播放"
        }}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    
      <div class="right">
        <div id="player"></div>
        <p class="title">{{ currentInfo.name }}</p>
        <div class="choose" v-if="list.length > 1">
          <p @click="changeVideo('pre')" :class="{ gray: currentIndex == 0 }">
            上一节
          </p>
          <p
            @click="changeVideo('next')"
            :class="{ gray: currentIndex == list.length - 1 }"
          >
            下一节
          </p>
        </div>
      </div>
  </div>
</template>

<script>
export default {
  name: "detail",
  data() {
    return {
     
      pass: "https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/tuoyu2/examinPass.png",
      noPass:
        "https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/tuoyu2/examinNoPass.png",
      radioImg:
        "https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/tuoyu2/radio.jpg",
      radioAImg:
        "https://pkusaas.oss-cn-beijing.aliyuncs.com/miniprogram/study_center/tuoyu2/radioActive.jpg",
      topData: [],
      pageLoading: false,
      id: "",
      type: "",
      player: "",
      name: "",
      from: "",
      url: "",
      goods_id: "",
      videoId: "",

      baseInfo: {},
      lessonId: "",
      list: [],
      currentIndex: -1,
      currentInfo: {},

      type: false, // false课程 true示范课
      videoType: '',
    };
  },
  watch: {
  },
  mounted() {
    this.$router.onReady(async () => {
      this.lessonId = this.$route.query.lesson_id;
      this.goods_id = this.$route.query.id;
      this.type = this.$route.query.type;
      this.getInit();
    });
    
  },
  methods: {
    changeVideo(type) {
      if (
        (type == "pre" && this.currentIndex == 0) ||
        (type == "next" && this.currentIndex == this.list.length - 1)
      ) {
        this.$toast("没有了");
        return;
      } else {
        this.currentIndex =
          type == "pre" ? this.currentIndex - 1 : this.currentIndex + 1;
        this.currentInfo = this.list[this.currentIndex];
        this.getVideo();
      }
    },

    async getInit() {
      this.pageLoading = true;
      let res = await this.$request.tuoyuDetail({ goods_id: this.goods_id });
      
      this.pageLoading = false;
      if (res.code == 0) {
        this.list = this.flatArr(res.data.data.list, []);
        // console.log(this.list);
        this.baseInfo = res.data.data.base; //课程介绍
        let id =
          this.$route.query.speak_id ||
          this.$route.query.chapter_id ||
          this.$route.query.lesson_id;
        this.currentIndex = this.list.findIndex((item, index) => {
          return (
            (!item.myType && item.id == this.$route.query.chapter_id) ||
            (item.myType == "lesson" &&
              item.id == this.$route.query.lesson_id) ||
            (item.myType == "speak" && item.id == this.$route.query.speak_id)
          );
        });
        this.currentInfo = this.list[this.currentIndex];
     
        this.$nextTick(()=>{
          this.getVideo();
        })

      } else {
        this.$toast(res.msg);
      }
    },
    flatArr(arr, idArr) {
      let myArr = [];

      arr.forEach((item) => {
        if (item.lesson && item.lesson.length) {
          // console.log("lesson");
          let arr = [
            { label: "chapterId", value: item.id },
            { label: "myType", value: "lesson" },
          ];
          // console.log(idArr);
          myArr.push(...this.flatArr(item.lesson, [...idArr, ...arr]));
        } else if (item.speak && item.speak.length) {
          // console.log("speak");
          let arr = [
            { label: "lessonId", value: item.id },
            { label: "myType", value: "speak" },
          ];
          myArr.push(...this.flatArr(item.speak, [...idArr, ...arr]));
        } else {
          idArr.forEach((v) => {
            item[v.label] = v.value;
          });
          myArr.push(item);
        }
      });
      return myArr;
    },

    async getVideo() {
      
      if (this.player) {
        this.videoType !='cc' ? this.player.dispose() : this.player.destroy();
      }
      let videoInfo = this.currentInfo.video_ids[0].resource[0];

      let id = videoInfo.ali_media_id;
      if (id) {
        this.videoType = 'ali'
        let res = await this.$api({
          method: "get",
          url: "/api/video/play-auth/get",
          data: {
            video_id: id,
          },
        });
        if (res.code == 0) {
          this.player = new Aliplayer(
            {
              id: "player",
              width: "100%",
              height: "480px",
              vid: res.data.data.videoId,
              playauth: res.data.data.playAuth,
              // isLive:true,
              skinLayout: [
                {
                  name: "bigPlayButton",
                  align: "blabs",
                  x: '50%',
                  y: '50%',
                },
                {
                  name: "H5Loading",
                  align: "cc",
                },
                {
                  name: "errorDisplay",
                  align: "tlabs",
                  x: 0,
                  y: 0,
                },
                {
                  name: "infoDisplay",
                },
                {
                  name: "tooltip",
                  align: "blabs",
                  x: 0,
                  y: 56,
                },
                {
                  name: "thumbnail",
                },
                {
                  name: "controlBar",
                  align: "blabs",
                  x: 0,
                  y: 0,
                  children: [
                    {
                      name: "progress",
                      align: "blabs",
                      x: 0,
                      y: 44,
                    },
                    {
                      name: "playButton",
                      align: "tl",
                      x: 15,
                      y: 12,
                    },
                    {
                      name: "timeDisplay",
                      align: "tl",
                      x: 10,
                      y: 7,
                    },
                    {
                      name: "fullScreenButton",
                      align: "tr",
                      x: 10,
                      y: 12,
                    },
                    {
                      name: "subtitle",
                      align: "tr",
                      x: 15,
                      y: 12,
                    },
                    {
                      name: "volume",
                      align: "tr",
                      x: 5,
                      y: 10,
                    },
                  ],
                },
              ],
            },
            function (player) {
              // console.log("The player is created.");
            }
          );
        } else {
          this.$toast(res.msg);
        }
      } else if (videoInfo.url) {
        this.videoType = 'url'
        this.player = new Aliplayer(
          {
            id: "player",
            width: "100%",
            height: "480px",
            source: videoInfo.url,
            cover: "",
            // isLive:true,
            skinLayout: [
              {
                name: "bigPlayButton",
                align: "blabs",
                x: '50%',
                y: '50%',
              },
              {
                name: "H5Loading",
                align: "cc",
              },
              {
                name: "errorDisplay",
                align: "tlabs",
                x: 0,
                y: 0,
              },
              {
                name: "infoDisplay",
              },
              {
                name: "tooltip",
                align: "blabs",
                x: 0,
                y: 56,
              },
              {
                name: "thumbnail",
              },
              {
                name: "controlBar",
                align: "blabs",
                x: 0,
                y: 0,
                children: [
                  {
                    name: "progress",
                    align: "blabs",
                    x: 0,
                    y: 44,
                  },
                  {
                    name: "playButton",
                    align: "tl",
                    x: 15,
                    y: 12,
                  },
                  {
                    name: "timeDisplay",
                    align: "tl",
                    x: 10,
                    y: 7,
                  },
                  {
                    name: "fullScreenButton",
                    align: "tr",
                    x: 10,
                    y: 12,
                  },
                  {
                    name: "subtitle",
                    align: "tr",
                    x: 15,
                    y: 12,
                  },
                  {
                    name: "volume",
                    align: "tr",
                    x: 5,
                    y: 10,
                  },
                ],
              },
            ],
          },
          function (player) {
            // console.log("The player is created.");
          }
        );
      }else if(videoInfo.ccid){
        this.videoType = 'cc'
        
        this.player = window.createCCH5Player({
          vid: videoInfo.ccid,
          siteid: "23EEB4513490D389",
          // vid: 'AA22BDEE61E7455A9C33DC5901307461',
          // siteid: "2661F9756E5C832E",
          autoStart: true,
          playertype: 1,
          isShare: "false",
          banDrag: "false",
          width: "100%",
          height: "480px",
          loop: "true",
          showFullScreenBtn: "false",
          useX5Play: "true",
          isShowUrlBtn: "false",
          isShowMoreBtn: "false",
          objectFit: "cover", // contain 、 fill 、 cover
          parentNode: "#player",
          showFullScreenBtn: true
        });
        // this.player = window.createCCH5Player({
        //             vid: 'AA22BDEE61E7455A9C33DC5901307461',
        //             siteid: "2661F9756E5C832E",
        //             autoStart: true,
        //             width: "100%",
        //             height: "380px",
        //             parentNode: document.getElementById('player')
        //         });
        window.on_h5player_error = (err) => {
          console.log('失败')
          console.log(err)
        }
        window.on_CCH5player_ready = (obj)=>{

        };
        window.on_CCH5player_play = (obj) =>{
        }
        window.on_CCH5player_pause = (obj) =>{
        }
        
      }else{
        this.$toast('暂无视频')
        return
      }
      
      
    },
    
   
    
  },
  computed: {
    
  },
  beforeRouteUpdate(to, from, next) {
    // console.log("beforeRouteUpdate");
    // console.log(to);
    // this.id = to.query.id;
    // this.getData();
    this.id = this.$route.query.id;
    this.goods_id = to.query.goods_id;
    this.videoId = to.query.videoId;
    this.type = to.query.type;
    this.name = to.query.name;
    this.from = to.query.from;
    this.url = to.query.url ? to.query.url : "";
    this.player.dispose();
    this.getInit();
    next();
  },
};
</script>
<style>
.prism-progress{
  display: none;
}
body {
  background: #fff;
}
.el-breadcrumb__inner a,
.el-breadcrumb__inner.is-link {
  color: #333;
  font-size: 14px;
  font-weight: normal;
}
.el-breadcrumb__separator {
  color: #adadad;
}
.el-breadcrumb__item:last-child .el-breadcrumb__inner,
.el-breadcrumb__item:last-child .el-breadcrumb__inner a,
.el-breadcrumb__item:last-child .el-breadcrumb__inner a:hover,
.el-breadcrumb__item:last-child .el-breadcrumb__inner:hover {
  color: #333;
  font-size: 14px;
  font-weight: normal;
}
</style>
<style lang="scss" scoped>
@import url("https://service.wormhoo.com/libs/js/vant2/index.css");
.gray {
  background: #d6d6d6 !important;
  color: #b3b3b3 !important;
}
.page {
  min-height: calc(100vh - 230px);
  overflow: hidden;
  margin: 0 auto;
  width: 800px;
  padding-top: 60px;
  .position {
    padding-top: 40px;
    background: #fff;
    padding-bottom: 25px;
  }
 
  .title {
    font-size: 24px;
    padding-top: 34px;
    padding-bottom: 40px;
  }

  .right {
    width: 800px;
  

    h2 {
      margin: 0;
    }
    
    .choose {
      display: flex;
      justify-content: flex-end;
      p {
        cursor: pointer;
        width: 122px;
        height: 34px;
        line-height: 34px;
        text-align: center;
        font-size: 14px;
        border-radius: 3px;
        border: 0;
        &:first-of-type {
          background: #0080d6;
          color: #fff;
        }
        &:nth-of-type(2) {
          margin-left: 20px;
          background: #f9f9f9;
          color: #0080d6;
        }
      }
      .active {
        color: #0080d6;
      }
      .gray {
        background: #e4e4e4;
        border: 1px solid #e4e4e4;
        color: #666;
      }
    }
  }

  #player {
    width: 100%;
    height: 480px;
    padding-bottom: 0;
    background: #000;
  }
}
</style>

<style lang="scss">
.prism-player .prism-big-play-btn{
  transform: translate(-50%, 50%);
}
video {
  height: 480px;
}
</style>
